.lineItemAddCell {
    position: relative;
}
.lineItemAddButton {
    display: none;
    padding: 5px;
    position: absolute;
    left: -15px;
    margin-left: 50%;
}
.lineItemAddButtonUp {
    top: -15px;
}
.lineItemAddButtonDown {
    bottom: -15px;
}
.lineItemRow:hover .lineItemAddButton {
    display: block;
}

.dragDropTable {
    border-radius: 0px !important;
    border: 1px solid rgb(223, 227, 235);
    box-shadow: none;
}
.dragDropTable tbody tr td,
.dangerouslyInnerHTMLStyle {
    word-break: break-word !important;
}
.dragDropTable .MuiTableCell-sizeSmall {
    padding: 6px 10px;
}
.dragDropTable.tablePaddingStyle .MuiTableCell-sizeSmall {
    padding: 10px !important;
}
.dragDropTable thead {
    background: #f5f8fa !important;
    color: rgba(0, 0, 0, 0.87) !important;
}
.dragDropTable tbody tr:hover {
    background: #f5f8fa !important;
}
.dragDropTable tbody tr:last-child td {
    border-bottom: 0px;
}
.cardBoxShadow {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 5px -1px;
}
/* .dragDropTable tr th,
.dragDropTable tr td {
    border-color: rgb(223, 227, 235);
    height: 50px;
    min-height: 50px;
    word-break: break-all;
} */
.dragDropTable tr th {
    background: #f5f8fa !important;
}
.document-action-row button {
    padding: 0.6rem;
}

.dragDropTable .dashboardProductTable tbody tr:last-child td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.stickyHeader {
    z-index: 3;
    position: sticky;
    top: -20px;
}

.stickyHeaderView {
    z-index: 3;
    position: sticky;
    top: 0px;
}
/* NEED TO REMOVE BELOW  AND MAKE INLINE CSS IN FILE IMPORT*/
.stepProgressbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.stepProgressbar li {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2px 0;
}
.stepProgressbar li::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    top: 16px;
    left: 50%;
    background-color: #e2e2e2;
}
.stepProgressbar li.active:after {
    background-color: #192d3e;
}

.stepProgressbar li div {
    margin: 0px;
}
.stepProgressbar li span {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid #e2e2e2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-weight: 700;
    position: relative;
    z-index: 999;
    background-color: #fff;
    color: #bdbdbd;
}
.stepProgressbar li p {
    padding-top: 10px;
    font-weight: 700;
}
.stepProgressbar li:last-child:after {
    display: none;
}

.stepProgressbar li.active span {
    background: #192d3e;
    color: #fff;
    border-color: #192d3e;
}
.stepProgressbar li.active p {
    color: #192d3e;
}
.fileText {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 96px;
    font-size: 16px;
    font-weight: 600;
    /*  background: #142635; */
    /* color: #fff; */
    justify-content: center;
    line-height: 1;
    overflow: hidden;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    cursor: pointer;
}
.MuiPickersLayout-root .MuiButton-text {
    color: #f07c00 !important;
}

.component-container {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-block;
}

.expandControl {
    display: none;
}

.selected-rnd.component-container {
    border: 1px dashed #0084ff;
}

.selected-rnd-primary .expandControl {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 0;
    background-color: #5cee22;
    position: absolute;
    border: 1px solid #000;
}

.selected-rnd-secondary .expandControl {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 0;
    background-color: #ffffff;
    position: absolute;
    border: 1px solid #000;
}

.position1 {
    left: calc(0% - 6px);
    top: calc(0% - 6px);
}
.position2 {
    left: calc(50% - 6px);
    top: calc(0% - 6px);
}
.position3 {
    right: calc(0% - 6px);
    top: calc(0% - 6px);
}
.position4 {
    right: calc(0% - 6px);
    top: calc(50% - 3px);
}
.position5 {
    right: calc(0% - 6px);
    bottom: calc(0% - 6px);
}
.position6 {
    left: calc(50% - 6px);
    bottom: calc(0% - 6px);
}
.position7 {
    left: calc(0% - 6px);
    bottom: calc(0% - 6px);
}
.position8 {
    left: calc(0% - 6px);
    top: calc(50% - 3px);
}
